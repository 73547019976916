<template>
    <div v-if="ready">
        <button @click="handleImprimir" class="btn btn-primary mt-5 ms-3" style="color: aliceblue;">Generar Excel <i
            class="fa-solid fa-download"></i></button>
      </div>
    <div class="container d-flex justify-content-center align-items-center text-center">
        
        <table class="table table-striped table-hover text-center" id="myTable">
            <thead>
                <tr>
                    <th>ID</th>
                    <th class="text-center">Beneficiario</th>
                    <th class="text-center">No.Referencia</th>
                    <th>Monto</th>
                    <th>Fecha Pagado</th>
                    <th class="text-center">IFI</th>
                    <th>No. Cuenta</th>
                    <th>Sucursal</th>
                    <th>Imagen</th>
                </tr>
            </thead>
            <tbody v-if="ready">
                <tr v-for="pago in pagos" :key="pago.id">
                    <td>{{ pago.id }}</td>
                    <td>{{ pago.nombreCliente }}</td>
                    <td>{{ pago.noReferenciaRecibo }}</td>
                    <td>{{ this.formatoNumero(pago.montoPagado) }} {{ this.moneda }}</td>
                    <td>{{ this.dateFormat(pago.fechaPagado) }}</td>
                    <td>{{ pago.ifi }}</td>
                    <td>{{ pago.noCuenta }}</td>
                    <td>{{ pago.sucursal }}</td>
                    <td>

                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-primary" @click="pagoSlug(pago.slug)" data-bs-toggle="modal"
                            data-bs-target="#exampleModal">
                            <i class="fa-solid fa-image" style="color:white"></i>
                        </button>
                    </td>
                </tr>

            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="10" class="text-center">
                        <div class="spinner-border text-warning text-center" role="status">
                            <span class="visually-hidden text-center">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="table-dark">

                    <td class="text-end"></td>
                    <td class="text-end"></td>
                    <td class="pt-4 pb-4"> <strong>Total Pagado:</strong> </td>
                    <td>{{ this.formatoNumero(this.totalMontoPagado) }} {{ this.moneda }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>

                    </td>
                </tr>
            </tfoot>


            

        </table>
        <!-- Modal -->
        <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Recibo</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="d-flex justify-content-center align-items-center">
                              <!-- <pdf-recibo :imgPath="imgPath" :pago="pago" class="me-3"></pdf-recibo> -->
                              <div class="contenedor">
                                <img class="imagen-recibo" :src="imgPath + '/recibo/' + pago.slug + '.jpg'" alt="Recibo">
                                <!-- <a :href="imgPath + '/recibo/' + pago.slug + '.pdf'" download>pdf</a>
                                <img :src="imgPath + '/recibo/' + pago.slug + '.jpg'" alt="Recibo"> -->

                              </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import Security from "../security";
import DataTables from "datatables.net";
import ExcelJS from "exceljs";


export default {
    data() {
        return {
            ready: false,
            pagos: [],
            moneda: "lps",
            imgPath: process.env.VUE_APP_IMAGE_URL,
            totalMontoPagado: 0,
            pago:{
                slug:''
            }
        }
    },
    props: {
        startDate: new Date,
        endDate: new Date,
        option: Boolean,
    },
    watch: {
        startDate: function () {
            // console.log('date', date)
            this.getPagosByRange()
        },
        endtDate: function () {
            // console.log('date', date)
            this.getPagosByRange()
        }
    },
    beforeMount() {
        if(this.option){
            this.getPagosByRange()
        }else{
            this.getAllReadecuted()
        }
    },
    updated() {
        if (this.ready) {
            this.initializeDataTable();
        }


    },
    methods: {
        getPagosByRange() {
            this.ready = false

            let payload = {
                startDate: this.startDate,
                endDate: this.endDate
            }
            fetch(process.env.VUE_APP_API_URL + `/admin/pagos/allByRange`, Security.requestOptions(payload))
                .then((response) => response.json())
                .then((response) => {
                    if (response.error) {
                        Swal.fire({
                            position: 'top-end',
                            icon: "error",
                            title: response.error,
                            showConfirmButton: false,
                            timer: 1500,
                        });

                    } else {

                        // this.pagos = response.data.pagos.reverse();
                        this.pagos = response.data.pagos;
                        this.ready = true
                        console.log('Pagos Hecho:', this.pagos);
                        this.calculateTotalMonto()

                    }
                })
                .catch(error => {
                    Swal.fire({
                        position: 'top-end',
                        icon: "error",
                        title: error,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        },
        getAllReadecuted() {
            this.ready = false

            fetch(process.env.VUE_APP_API_URL + `/admin/pagos/allReadecuated`, Security.requestOptions({}))
                .then((response) => response.json())
                .then((response) => {
                    if (response.error) {
                        Swal.fire({
                            position: 'top-end',
                            icon: "error",
                            title: response.error,
                            showConfirmButton: false,
                            timer: 1500,
                        });

                    } else {

                        // this.pagos = response.data.pagos.reverse();
                        this.pagos = response.data.pagos;
                        this.ready = true
                        console.log('Pagos Hecho:', this.pagos);
                        this.calculateTotalMonto()

                    }
                })
                .catch(error => {
                    Swal.fire({
                        position: 'top-end',
                        icon: "error",
                        title: error,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        },
        initializeDataTable() {
            console.log('Despues Iniciar');
            const myTable = document.querySelector("#myTable");
            if (myTable) {
                new DataTables(myTable);
            }


        },
        calculateTotalMonto() {
            this.totalMontoPagado = 0; // Reset total
            if (this.pagos) {
                this.pagos.forEach(pago => {
                    this.totalMontoPagado += pago.montoPagado;
                });
            }
        },
        formatoNumero(numero) {
            return new Intl.NumberFormat("en-US").format(numero)
        },
        dateFormat(param) {

            var fechaISO = param;


            // Crear un objeto Date con la fecha proporcionada
            var fecha = new Date(fechaISO);

            // Obtener el día del mes (agregando un cero inicial si es necesario)
            var dia = ('0' + fecha.getUTCDate()).slice(-2);

            // Obtener el mes (agregando un cero inicial si es necesario)
            var mes = ('0' + (fecha.getUTCMonth() + 1)).slice(-2);

            // Obtener el año
            var anio = fecha.getUTCFullYear();

            // Formatear la fecha según tus necesidades
            var fechaFormateada = dia + '/' + mes + '/' + anio;

            console.log(fechaFormateada); // Salida: 01/12/2023

            return fechaFormateada;

        },
        pagoSlug(slug){
            this.pago.slug = slug
        },
        handleImprimir() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(this.depto ? this.depto[0] : "Data");
      worksheet.columns = [
        // { header: "ID", key: "id", width: 10 },
        { header: "Beneficiario", key: "beneficiario", width: 40 },
        { header: "No Referencia recibo", key: "noReferencia", width: 20 },
        { header: "Total Monto", key: "monto", width: 20 },
        { header: "Capital Pago", key: "capitalPago", width: 20 },
        { header: "Interes Pago", key: "interesPago", width: 20 },
        { header: "Mora Pago", key: "moraPago", width: 20 },
        { header: "Fecha Pagado", key: "fechaPagado", width: 20 },
        { header: "IFI", key: "ifi", width: 20 },
        { header: "No. Cuenta", key: "noCuenta", width: 20 },
        { header: "Sucursal", key: "sucursal", width: 20 },

      ];
      worksheet.getRow(1).font = { bold: true };

      const mappedData = this.pagos.map((pago) => ({
        beneficiario: pago.nombreCliente,
        noReferencia: pago.noReferenciaRecibo,
        monto: pago.montoPagado ,
        capitalPago: pago.capitalPago ,
        interesPago: pago.interesPago ,
        moraPago: pago.moraPago ,
        fechaPagado: this.dateFormat(pago.fechaPagado),
        ifi: pago.ifi ,
        noCuenta: pago.noCuenta,
        sucursal: pago.sucursal,

      }));
      worksheet.addRows(mappedData);
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        // a.setAttribute("download", `CARTERA_${(this.sucursalesRoles[0] == 'ALL' ? "HISTORICA" : this.sucursalesRoles[0]) + "_AHDECO" + "_" + this.hoy()}.xlsx`);
        a.setAttribute("download", `Pagos-${this.dateFormat(this.startDate)}-${this.dateFormat(this.endDate)}.xlsx`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    },

}
</script>

<style scoped>
.modal-img > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: white;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.imagen-recibo{
    width: 100%;
}
button{
    margin-top: 0;
}
</style>
