<template>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex">
              <div class="d-flex  bienvenida">

                <!-- <h3 class="display-3 d-flex justify-content-center align-items-center">Te damos la Bienvenida {{ store.user.first_name }} </h3> -->
                <h3 class="display-5 mt-4 mb-4">¡Te damos la Bienvenida {{ store.user.first_name }}! 👋</h3>
              </div>
              <div>

              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row d-flex justify-content-center imagen-inicio">
          <img src="@/assets/img1.jpg" alt="">
        </div> -->

        <div class="row m-3">
          <div class="col-12 col-lg-6 video">
            <iframe
              src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fahdecohonduras%2Fvideos%2F569941971991652%2F&show_text=false&width=560&t=0"
              width="560" height="380" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
          <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <iframe
              src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fahdecohonduras%2Fvideos%2F1313677152847578%2F&show_text=false&width=560&t=0"
              width="560" height="380" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12 col-lg-6 video">
            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fahdecohonduras%2Fposts%2Fpfbid06tr19vVdzgYGUGGF9RdogtKPQTqWo6GRFx5W7ZuSSvJgddnjmK9Kpvyfsxrwmvw6l&show_text=false&width=500&is_preview=true" width="500" height="498" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
          <div class="col-12 col-lg-6 video">
            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fahdecohonduras%2Fposts%2Fpfbid06c8sav27xGJcXy1mE5f1CUjsrgRJTjsnYX6jEnkGgtStoXZxPoBLnPPfGxM7a8mQl&show_text=false&width=500&is_preview=true" width="500" height="497" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <img
              src="https://scontent.ftgu3-4.fna.fbcdn.net/v/t39.30808-6/278331214_549710596503235_4157467180788882263_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=dd5e9f&_nc_eui2=AeHe4qMApZcdALkE4QoaBwzVYz2G2m_psBhjPYbab-mwGJ4zQFP2L6fje-jbu7zID9NVBuOcLvHNhmq3VxtcyyrW&_nc_ohc=K_AF2gJLA6MAX-7JIE7&_nc_oc=AQnjIM56cGWV7X_KGIURr3z4LUuAQTSZZh6Hwe_gqlWn0ecvDq45RS6cJsEOLbIE3DY&_nc_ht=scontent.ftgu3-4.fna&oh=00_AfADplUtYO87WZxiSMlvLOzFJ5dkfoJx0YpEcfE-O7pbQA&oe=65A0E86E"
              alt="Mision de Ahdeco">
          </div>
          <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <img
              src="https://scontent.ftgu3-3.fna.fbcdn.net/v/t39.30808-6/278324748_549711716503123_3692718033667803673_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=dd5e9f&_nc_eui2=AeE1QMfZuIw6Ss-6gaTSnKS39AsIyGORiNv0CwjIY5GI22LNgdFEEhdLOfioWWaVSF2VxJLP1I0HKFcIxyy15aXz&_nc_ohc=gDxLbMTEEFUAX-DAQ6C&_nc_ht=scontent.ftgu3-3.fna&oh=00_AfDYZyljWvRy5T-13wKnIfIyxJVpbCAhl83_UBYW3sRKRg&oe=65A09660"
              alt="Vision De Ahdeco">
          </div>
        </div>
        <div class="row d-flex justify-content-center ">
          <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <img
              src="https://scontent.ftgu3-4.fna.fbcdn.net/v/t39.30808-6/278352019_549712129836415_122211279015253221_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=dd5e9f&_nc_eui2=AeGEe24s_3oEdM2O1XujuKN8wPIlQjbTC0fA8iVCNtMLR0tcXH1faWVhES3hv8sP1NckTPpg-5jSIA6m40FfJJhU&_nc_ohc=hSydVA57yMwAX-UrkhH&_nc_ht=scontent.ftgu3-4.fna&oh=00_AfDGnktDbiW4FOLKg3N1JkMPr5jBNUctSwwYWxxwvDgWJA&oe=65A0BA1A"
              alt="Valores de Ahdeco">
          </div>

        </div> -->






      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "./Footer-App.vue"

import { store } from "./store";
export default {
  name: 'DashboardApp',
  components: {
    Footer,
  },
  data() {
    return {
      componentKey: 0,
      reload: false,
      store,
    }
  },
  mounted() {
    console.log(this.store.roles)
    console.log(this.store.user.first_name);
    console.log(this.store.user);
    // console.log(document.cookie.split(';').find(cookie => cookie.startsWith('roles=')));
  }
}
</script>

<style scoped>
.card {
  border-radius: 12px;
  /* border-left-color: #4285f4; */
  /* border-top: 5px solid #ff910088; */
  border-top: 5px solid #FEC377;
  /* border-left: 5px solid #4286f467; */
  border-left: 5px solid #B3CEFB;
  padding: 50px;
  /* background-image: url('~@/assets/fondoNavidad.png'); */
  /* Ajusta el tamaño del fondo para abarcar todo el div */
  background-size: cover;
  /* padding-bottom: 25px; */

  /* Fija el fondo para que no se mueva al hacer scroll */
  /* background-attachment: fixed; */

  /* Centra el contenido verticalmente */

  /* Ajusta el alto al 100% del viewport */
  /* height: 100vh; */

}

@media (max-width: 767px) {
  .card {
    padding: 18px;
    /* Elimina el padding para dispositivos móviles */
  }

}

@media (max-width: 435px) {
  iframe {

    height: 100%;
    margin-bottom: 5px;
  }

}

iframe {
  width: 100%;
  /* height: 100%; */
  border: none;
  overflow: hidden;
  border-radius: 10px;
}

img {
  width: 100%;
  height: 100%;
}


.imagen-inicio img{
  border-radius: 5px;
}

.bienvenida {
  backdrop-filter: blur(5px);
  background-color: #00000066;
  /* border: 5px solid #000000; */
  color: white;
  /* font-weight: 600; */

  padding-inline: 18px;
  /* border-left: 5px solid #4286f467; */
  /* border-left: 5px solid #B3CEFB; */
  /* border-bottom: 5px solid #B3CEFB; */
  border-radius: 5px;
  margin-bottom: 20px;

}
</style>