<template>
  <div v-if="isOpen" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <button class="close-btn" @click="closeModal">✖</button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
  },
  emits: ["close"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* width: 100vw;
  height: 100vh; */
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  /* z-index: 9999; Asegura que esté al frente */
  z-index: 10000; /* Asegura que el contenido esté sobre la capa oscura */

}

.modal-content {
  background: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 400px;
  max-width: 90%;
  cursor: default;
  /* position: relative; */
  z-index: 10000; /* Asegura que el contenido esté sobre la capa oscura */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  z-index: 10000; /* Asegura que el contenido esté sobre la capa oscura */

}
</style>
