<template>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class=" cardplay">
        <div class="row">
          <div class="col-md-12 grid-margin d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-center align-items-center">

                <h3 class="display-3 d-flex justify-content-center align-items-center">Dashboard</h3>
              </div>
              <div>

              </div>
            </div>
          </div>
        </div>
        <p>Sucursal</p>
        <select v-model="sucursal" class="form-select mb-3" aria-label="Default select example">
          <!-- <option selected>Sucursal</option> -->
          <!-- <option v-for="sucursal in this.userRoles"  :value="this.user">One</option> -->
          <!-- <option v-for="sucursal in userRoles" :value="sucursal">{{ sucursal }}</option> -->
          <option v-for="(sucursal, index) in this.sucursales" :key="index" :value="sucursal">{{ sucursal }}</option>

        </select>
        <!-- Content -->

        <div v-if="ready">
          <div class="row">
            <!-- <div class="col-md-12 grid-margin stretch-card"> -->
            <!-- <div :class="(this.sucursal=='ALL')?'col-md-12 grid-margin stretch-card':'col-md-3 grid-margin stretch-card'">
            <div class="card">
              <div class="card-body">
                <h4 class="grey text-md-center text-xl-left">Total Créditos:</h4>
                <div
                  class="d-flex flex-column flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                  <h4 class="text-center mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{ this.dashboard.noCreditos }}
                  </h4>
                  <p class="mt-3 mb-0 mt-2 text-center"> <b>Saldo Capital:</b> {{
                    this.formatoNumero(this.dashboard?.saldoActivos + this.dashboard?.saldoVencidos+ this.dashboard?.saldoLegal) }} {{ this.moneda
                    }} </p>

                  <p class="mt-1 mb-0 text-success">100% </p>

                </div>
              </div>
            </div>
          </div> -->
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="text-success text-md-center text-xl-left">Cartera Activa:</h4>
                  <div
                    class="d-flex flex-column flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                    <h4 class="text-center mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{ this.dashboard.noActivos +
                      this.dashboard.noVencidos }} créditos
                    </h4>
                    <p class="mt-3 mb-0 mt-2 text-center"> <b>Saldo Capital:</b> {{
                      this.formatoNumero(this.dashboard?.saldoActivos + this.dashboard?.saldoVencidos) }} {{ this.moneda
                      }} </p>

                    <!-- <p class="mb-0 mt-2 text-success">{{
                    ((this.dashboard.noActivos * 100) / this.dashboard.noCreditos).toFixed(2) }}%</p> -->
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-3 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="text-success text-md-center text-xl-left">Créditos Activos:</h4>
                <div
                  class="d-flex flex-column flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                  <h4 class="text-center mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{ this.dashboard.noActivos }}
                  </h4>
                  <p class="mt-3 mb-0 mt-2 text-center"> <b>Saldo Capital:</b> {{
                    this.formatoNumero(this.dashboard?.saldoActivos) }} {{ this.moneda }} </p>

                  <p class="mb-0 mt-2 text-success">{{
                    ((this.dashboard.noActivos * 100) / this.dashboard.noCreditos).toFixed(2) }}%</p>
                </div>
              </div>
            </div>
          </div> -->
            <!-- <div class="col-md-3 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="text-warning text-md-center text-xl-left">Créditos Vencidos:</h4>
                <div
                  class="d-flex flex-column flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                  <h4 class="text-center mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{ this.dashboard.noVencidos }}
                  </h4>
                  <p class="mt-3 mb-0 mt-2 text-center"> <b>Saldo Capital:</b> {{ this.formatoNumero(
                    this.dashboard?.saldoVencidos) }} {{ this.moneda }} </p>

                  <p class="mb-0 mt-2 text-warning">{{
                    ((this.dashboard.noVencidos * 100) / this.dashboard.noCreditos).toFixed(2) }}%</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="text-primary text-md-center text-xl-left">Créditos Cancelados</h4>
                <div
                  class="d-flex flex-column flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                  <h4 class="text-center mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{ this.dashboard.noCancelados }}
                  </h4>
                  <p class="mt-3 mb-0 mt-2 text-center"> <b>Saldo Capital:</b> {{ this.formatoNumero(
                    this.dashboard?.saldoCancelados) }} {{ this.moneda }} </p>

                  <p class="mb-0 mt-2 text-primary">{{
                    ((this.dashboard.noCancelados * 100) / this.dashboard.noCreditos).toFixed(2) }}%</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="this.sucursal=='ALL'" class="col-md-3 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="text-primary text-md-center text-xl-left">Créditos Legales</h4>
                <div
                  class="d-flex flex-column flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                  <h4 class="text-center mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{ this.dashboard.noLegal }}
                  </h4>
                  <p class="mt-3 mb-0 mt-2 text-center"> <b>Saldo Capital:</b> {{ this.formatoNumero(
                    this.dashboard?.saldoLegal) }} {{ this.moneda }} </p>

                  <p class="mb-0 mt-2 text-primary">{{
                    ((this.dashboard.noLegal * 100) / this.dashboard.noCreditos).toFixed(2) }}%</p>
                </div>
              </div>
            </div>
          </div> -->


          </div>



          <!-- ****************************************************************************************** -->
          <!-- Rangos de Mora -->
          <div class="row card-2 horizontal-scrollable">
            <div class="col-12 col-lg-6">
              <h4 class="text-danger mb-2 text-center">Rangos de Mora</h4>

              <table class="table" id="my-data-table">
                <thead>
                  <tr>
                    <th scope="col">Rangos</th>
                    <th scope="col">No.Clientes</th>
                    <th scope="col">Saldo Capital</th>
                    <th scope="col">Porcentajes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Al Día</th>
                    <td>{{ this.dashboard.noVigente }}</td>
                    <td>{{ this.formatoNumero(
                      this.dashboard?.saldoVigente) }} {{ this.moneda }} </td>
                    <td>{{ ((this.dashboard?.saldoVigente / this.totalSaldoRangos) * 100).toFixed(2) }} %</td>
                  </tr>
                  <tr>
                    <th scope="row">1-30</th>
                    <td>{{ this.dashboard.no30Dias }}</td>
                    <td>{{ this.formatoNumero(
                      this.dashboard?.saldo30Dias) }} {{ this.moneda }} </td>
                    <td>{{ ((this.dashboard?.saldo30Dias / this.totalSaldoRangos) * 100).toFixed(2) }} %</td>

                  </tr>
                  <tr>
                    <th scope="row">31-60</th>
                    <td>{{ this.dashboard.no60Dias }}</td>
                    <td>{{ this.formatoNumero(
                      this.dashboard?.saldo60Dias) }} {{ this.moneda }} </td>
                    <td>{{ ((this.dashboard?.saldo60Dias / this.totalSaldoRangos) * 100).toFixed(2) }} %</td>

                  </tr>
                  <tr>
                    <th scope="row">61-89</th>
                    <td>{{ this.dashboard.no89Dias }}</td>
                    <td>{{ this.formatoNumero(
                      this.dashboard?.saldo89Dias) }} {{ this.moneda }} </td>
                    <td>{{ ((this.dashboard?.saldo89Dias / this.totalSaldoRangos) * 100).toFixed(2) }} %</td>

                  </tr>
                  <tr>
                    <th scope="row">90-Adelante</th>
                    <td>{{ this.dashboard.noMoraContable }}</td>
                    <td>{{ this.formatoNumero(
                      this.dashboard?.saldoMoraContable) }} {{ this.moneda }} </td>
                    <td>{{ ((this.dashboard?.saldoMoraContable / this.totalSaldoRangos) * 100).toFixed(2) }} %</td>

                  </tr>
                  <tr class="table-danger">
                    <th scope="row">Total</th>

                    <th>{{ this.noClientesRangos }} </th>
                    <th>{{ this.formatoNumero(
                      this.totalSaldoRangos) }} {{ this.moneda }} </th>
                    <th>100%</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-6 col-12 mt-lg-0 mt-4 d-flex flex-column justify-content-center">
              <p class="text-center">{{ this.sucursal }}</p>
              <h4 class="mb-2 text-center text-warning">Mora en Porcentajes</h4>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Mora 1-30</th>
                    <th scope="col">Mora Financiera</th>
                    <th scope="col">Mora Contable</th>
                    <th scope="col">Mora Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ ((this.dashboard?.saldo30Dias / this.totalSaldoRangos) * 100).toFixed(2) }} %</td>

                    <th>{{ (
                      ((this.dashboard?.saldo30Dias + this.dashboard?.saldo60Dias + this.dashboard?.saldo89Dias) /
                        this.totalSaldoRangos) * 100).toFixed(2) }} %</th>

                    <th>{{ (
                      ((this.dashboard?.saldoMoraContable) / this.totalSaldoRangos) * 100).toFixed(2) }} %</th>


                    <th>{{ ((((this.dashboard?.saldo30Dias + this.dashboard?.saldo60Dias + this.dashboard?.saldo89Dias)
                      /
                      this.totalSaldoRangos) * 100) + (((this.dashboard?.saldoMoraContable) / this.totalSaldoRangos) *
                        100
                      )).toFixed(2) }} %</th>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
          <!-- *************************************************************** -->
          <div class="row mt-5 mb-5">
            <div class="col-12 col-lg-4  pe-5">
              <h3 class="display-4 mb-3 text-center text-lg-start">Es Localizable</h3>
              <div class="d-flex justify-content-evenly justify-content-lg-between">
                <strong>Localizables: </strong>
                <p>{{ this.dashboard.noLocalizables }} Créditos</p>

              </div>
              <div class="d-flex justify-content-evenly justify-content-lg-between mb-5">
                <strong>No Localizables: </strong>
                <p>{{ this.dashboard.noNoLocalizables }} Créditos</p>
              </div>
            </div>
            <div class="col-12 col-lg-8">

              <h3 class="display-4 text-center">Estado Proceso</h3>

              <div class="row estadoProceso text-center">
                <!-- <div class="col-1"></div> -->
                <div class="col-12 col-lg-3">
                  <strong>Extrajudicial</strong>
                  <p>{{ this.dashboard.estadoProceso?.noExtrajudicial }} Créditos</p>
                </div>
                <div class="col-12 col-lg-2">
                  <strong>Judicial/Legal</strong>
                  <p>{{ this.dashboard.estadoProceso?.noJudicial }} Créditos</p>
                </div>
                <div class="col-12 col-lg-2">
                  <strong>Castigo</strong>
                  <p>{{ this.dashboard.estadoProceso?.noCastigo }} Créditos</p>
                </div>
                <div class="col-12 col-lg-2">
                  <strong>Irregular</strong>
                  <p>{{ this.dashboard.estadoProceso?.noIrregular }} Créditos</p>
                </div>
                <div class="col-12 col-lg-3">
                  <strong>Normal</strong>
                  <p>{{ this.dashboard.estadoProceso?.noNormal }} Créditos</p>
                </div>
              </div>

            </div>
          </div>

          <!-- ******************************************************************* -->

          <MapClientes :key="`${sucursal}`"   :sucursal="this.sucursal" />

          <!-- Por Producto -->
          <!-- <FinancialProductsChart v-if="this.ready === true" :chartData="this.chartData" /> -->

          <!-- Estadisticas -->

          <div class="container mt-5">
            <div class="row">
              <h4 class="text-center mb-4">Cantidad de Créditos por Género</h4>
              <div class="col-md-6 grid-margin stretch-card">
                <div class="card-edades">
                  <div class="card-body">
                    <h4 class="text-primary text-md-center text-xl-left">Masculino</h4>
                    <div
                      class="d-flex flex-column flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                      <h4 class="text-center mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{ this.dashboard.noMasculino
                      }}
                        créditos
                      </h4>
                      <p class="mt-3 mb-0 mt-2 text-center"> <b>Saldo Capital:</b> {{
                        this.formatoNumero(this.dashboard?.saldoMasculino) }} {{ this.moneda }} </p>


                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 grid-margin stretch-card">
                <div class="card-edades">
                  <div class="card-body">
                    <h4 class=" text-md-center text-xl-left" style="color: #F66799;">Femenino:</h4>
                    <div
                      class="d-flex flex-column flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                      <h4 class="text-center mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{ this.dashboard.noFemenino }}
                        créditos
                      </h4>
                      <p class="mt-3 mb-0 mt-2 text-center"> <b>Saldo Capital:</b> {{
                        this.formatoNumero(this.dashboard?.saldoFemenino) }} {{ this.moneda }} </p>

                    </div>
                  </div>
                </div>
              </div>
              <h4 class="text-center mb-4">Cantidad de Créditos por Edad</h4>
              <div class="col-md-4 grid-margin stretch-card">
                <div class="card-edades">
                  <div class="card-body">
                    <h4 class="text-primary text-md-center text-xl-left">Jovenes</h4>
                    <h5 class="text-primary text-md-center text-xl-left">0-24 años</h5>
                    <div
                      class="d-flex flex-column flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                      <h4 class="text-center mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{ this.dashboard.noJovenes }}
                        créditos
                      </h4>
                      <p class="mt-3 mb-0 mt-2 text-center"> <b>Saldo Capital:</b> {{
                        this.formatoNumero(this.dashboard?.saldoJovenes) }} {{ this.moneda }} </p>


                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 grid-margin stretch-card">
                <div class="card-edades">
                  <div class="card-body">
                    <h4 class=" text-md-center text-xl-left" style="color: #FFA700;">Adulto:</h4>
                    <h5 class=" text-md-center text-xl-left" style="color: #FFA700;">25-64 años</h5>

                    <div
                      class="d-flex flex-column flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                      <h4 class="text-center mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{ this.dashboard.noAdultos }}
                        créditos
                      </h4>
                      <p class="mt-3 mb-0 mt-2 text-center"> <b>Saldo Capital:</b> {{
                        this.formatoNumero(this.dashboard?.saldoAdultos) }} {{ this.moneda }} </p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 grid-margin stretch-card">
                <div class="card-edades">
                  <div class="card-body">
                    <h4 class=" text-md-center text-xl-left" style="color: #008C2B;">Adulto Mayor:</h4>
                    <h5 class=" text-md-center text-xl-left" style="color: #008C2B;">Mayores a 65 años</h5>
                    <div
                      class="d-flex flex-column flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                      <h4 class="text-center mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{
                        this.dashboard.noAdultosMayores }} créditos
                      </h4>
                      <p class="mt-3 mb-0 mt-2 text-center"> <b>Saldo Capital:</b> {{
                        this.formatoNumero(this.dashboard?.saldoAdultosMayores) }} {{ this.moneda }} </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div v-else>
          <div class="d-flex justify-content-center">
            <div class="spinner-border text-success" role="status" style="width: 3rem; height: 3rem;">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "./Footer-App.vue"
import Security from "./security";
// import FinancialProductsChart from './FinancialProductsChart.vue';

import { store } from "./store";
import MapClientes from "./Map-Clientes.vue";
export default {
  name: 'DashboardApp',
  components: {
    Footer,
    // FinancialProductsChart,
    MapClientes,

  },
  data() {
    return {
      componentKey: 0,
      reload: false,
      store,
      moneda: 'lps',
      ready: false,
      dashboard: {},
      sucursales: [],
      sucursal: '',
      productoNombre: [],
      productoResultado: [],

      noClientesRangos: 0,
      totalSaldoRangos: 0.0,

      chartData: {
        labels: [],
        datasets: [{
          label: 'Cantidad de créditos por producto',
          backgroundColor: '#FFC477',
          // data: [999, 600, 170, 620]
          data: []

        }]
      }
    }
  },
  beforeMount() {
    {
      this.sucursales = Security.getUserSucursal()[0];
      this.sucursal = this.sucursales[0]

      if (this.sucursales[0] === 'ALL') {
        let sucursales = ['FRANCISCO MORAZAN', 'EL PARAISO', 'ATLANTIDA', 'COPAN', 'LEMPIRA', 'OCOTEPEQUE', 'OLANCHO', 'SANTA BARBARA', 'COMAYAGUA AGRICOLA', 'OLANCHO AGRICOLA', 'ATLANTIDA AGRICOLA', 'CHOLUTECA AGRICOLA', 'COLON AGRICOLA', 'EL PARAISO AGRICOLA', 'FRANCISCO MORAZAN AGRICOLA', 'INTIBUCA AGRICOLA', 'LEMPIRA AGRICOLA', 'LEMPIRA/TOMALA', 'LEMPIRA/ERANDIQUE', 'YORO AGRICOLA']

        this.sucursales = this.sucursales.concat(sucursales)


      }
      fetch(process.env.VUE_APP_API_URL + "/admin/credito/dashboard-data", Security.requestOptions({ sucursal: this.sucursal }))
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.$emit('error', response.message);

            console.error(response.message)
          } else {
            console.log('Dashboards Datos:', response.data)

            this.dashboard = response.data.dashboardData,

              this.totalSaldoRangos = (this.dashboard.saldoVigente + this.dashboard.saldo30Dias + this.dashboard.saldo60Dias + this.dashboard.saldo89Dias + this.dashboard.saldoMoraContable)
            this.noClientesRangos = (this.dashboard.noVigente + this.dashboard.no30Dias + this.dashboard.no60Dias + this.dashboard.no89Dias + this.dashboard.noMoraContable)



            // for (const key in this.dashboard.productos) {
            //   if (Object.prototype.hasOwnProperty.call(this.dashboard.productos, key)) {
            //     // console.log("Recorre");
            //     console.log(key, this.dashboard.productos[key]);
            //     this.productoNombre.push(key) 
            //     this.chartData.labels.push(key) 
            //     this.chartData.datasets[0].data.push(this.dashboard.productos[key]) 
            //   }
            // }



          }
        })
        .catch(error => {
          this.$emit('error', error)
        })

      // this.ready = true;
    }

  },

  mounted() {
    console.log(this.store.roles)
    console.log(this.store.user.first_name);
    console.log(this.store.user);
    // console.log(document.cookie.split(';').find(cookie => cookie.startsWith('roles=')));
  },
  watch: {
    sucursal(sucursal) {
      this.ready = false
      // console.log(watch);
      fetch(process.env.VUE_APP_API_URL + "/admin/credito/dashboard-data", Security.requestOptions({ sucursal: sucursal }))
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.$emit('error', response.message);

            console.error(response.message)
          } else {
            console.log('Dashboards Datos:', response.data)

            this.dashboard = response.data.dashboardData,

              this.totalSaldoRangos = (this.dashboard.saldoVigente + this.dashboard.saldo30Dias + this.dashboard.saldo60Dias + this.dashboard.saldo89Dias + this.dashboard.saldoMoraContable)
            this.noClientesRangos = (this.dashboard.noVigente + this.dashboard.no30Dias + this.dashboard.no60Dias + this.dashboard.no89Dias + this.dashboard.noMoraContable)




            for (const key in this.dashboard.productos) {
              if (Object.prototype.hasOwnProperty.call(this.dashboard.productos, key)) {
                // console.log("Recorre");
                console.log(key, this.dashboard.productos[key]);
                this.productoNombre.push(key)
                this.chartData.labels.push(key)
                this.chartData.datasets[0].data.push(this.dashboard.productos[key])
              }
            }
            this.ready = true;

          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
    }
  },
  methods: {
    formatoNumero(numero) {
      return new Intl.NumberFormat("en-US").format(numero)
    },
  },

}
</script>

<style scoped>
.cardplay {
  border-radius: 12px;
  /* border-left-color: #4285f4; */
  border-top: 5px solid #ff910088;
  border-left: 5px solid #4286f467;
  padding: 50px;
  background-color: #FFFFFF;
}

.card {
  border-radius: 7px;
  /* border-top: 1px solid #c5c5c588; */
  /* border-left: 1px solid #bebebe; */
}

@media (max-width: 767px) {
  .card {
    padding: 18px;
    /* Elimina el padding para dispositivos móviles */
  }
}

.card-body {
  height: 100%;
}

iframe {
  width: 100%;
  /* height: 100%; */
  border: none;
  overflow: hidden;
}

img {
  width: 80%;
  /* height: 100%; */
  border: none;
  overflow: hidden;
  border-radius: 70px;
}

.grey {
  color: grey;
}

tr,
th,
td,
th {
  font-size: 12px;
}

.card-2 {
  border-radius: 7px;
  border-top: 1px solid #c5c5c588;
  border-left: 1px solid #bebebe;
  padding-block: 30px;
}

.card-edades {
  display: flex;
  width: 95%;
  border-radius: 7px;
  /* border: 1px solid #acacac88; */
  /* border-left: 2px solid #bebebe; */
  padding-block: 30px;

  /* width: 100px; */
  transition: width 1s ease 0s;

  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.3);
}

.card-edades:hover {
  width: 100%;
}

.estadoProceso {
  background-color: #ebebeb;
  padding: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 15px;
}
</style>