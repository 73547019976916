<template>
    <div class=" mb-5">

        <div class="col-3 mb-3"> 
            <label for="exampleFormControlSelect1" class="form-label">Selecciona la Mora</label>
            <select class="form-select" aria-label="Default select example" v-model="moraSeleccionada">
                <option selected>Mostrar Todos</option>
                <option selected>Al Día</option>
                <option selected>1-30</option>
                <option selected>31-60</option>
                <option selected>61-89</option>
                <option selected>90-Adelante</option>
            </select>
        </div>

        <h4>Total: {{ markers.length }} beneficiarios georeferenciados</h4>
        <div id="map" class="map-container">
            <ModalApp :isOpen="modalVisible" @close="closeModal">
                <h4>{{ this.beneficiario.nombres + " " + this.beneficiario.apellidos }}</h4>
                <p>{{ this.beneficiario.dni }}</p>

                <div class="d-flex justify-content-between">
                    <b class="me-2">Departamento: </b>
                    <p>{{ this.beneficiario.departamento }}</p>

                </div>
                <div class="d-flex justify-content-between">
                    <b class="me-2">Municipio: </b>
                    <p>{{ this.beneficiario.municipio }}</p>

                </div>
                <div class="d-flex justify-content-between">
                    <b class="me-2">Celular: </b>
                    <p>{{ this.beneficiario.celular }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <b class="me-2">telefono: </b>
                    <p>{{ this.beneficiario.telefono }}</p>

                </div>
                <div class="d-flex justify-content-between">
                    <b class="me-2">Colonia: </b>
                    <p>{{ this.beneficiario.colonia }}</p>


                </div>
                <div class="d-flex justify-content-between">
                    <b class="me-2">Avenida: </b>
                    <p>{{ this.beneficiario.avenida }}</p>


                </div>
                <div class="d-flex justify-content-between">
                    <b class="me-2">Calle: </b>
                    <p>{{ this.beneficiario.calle }}</p>

                </div>
                <div class="d-flex justify-content-between">
                    <b class="me-2">No Casa: </b>

                    <p>{{ this.beneficiario.no_casa }}</p>

                </div>
                <div class="d-flex mt-2 mb-2  justify-content-between punto-referencia">
                    <b class="me-2">Punto de Referencia: </b>

                    <p class="d-flex justify-content-end">{{ this.beneficiario.punto_referencia }}</p>

                </div>
                <div class="d-flex justify-content-between">
                    <b class="me-2">Coordenadas: </b>
                    <p class="d-flex justify-content-end">{{ this.beneficiario.lat + " " + this.beneficiario.lng }}</p>

                </div>

                <a :href="`/solicitante/${beneficiario.id}`" target="_blank" class="btn btn-inverse-primary">Ir a
                    Cliente</a>
                <button @click="irCredito(beneficiario.dni)" class="btn btn-inverse-primary">Ver Créditos</button>

            </ModalApp>
        </div>


    </div>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Security from "./security";
import ModalApp from "./Modal-App.vue";

export default {
    name: "MapClientes",

    data() {
        return {
            coordenadas: [
                // { lat: 14.0723, lng: -87.1921 },
                // { lat: 15.2000, lng: -88.0333 },
            ],
            map: null,
            markers: [],
            customIcon: L.icon({
                iconUrl: `${process.env.VUE_APP_IMAGE_URL}/ahdecoLocation.svg`,
                iconSize: [32, 32],
                iconAnchor: [16, 32],
                popupAnchor: [0, -32],
            }),
            contadorDepartamentos: {},
            contadorMunicipios: {},
            beneficiario: {},
            ready: false,
            modalVisible: false,
            moraSeleccionada: "Mostrar Todos",
        };
    },
    props: {
        sucursal: {
            type: String,
            default: () => "ALL",
        },
    },
    components: {
        ModalApp,
    },
    mounted() {
        this.getCoordenadas();
        this.initMap();
    },
    watch: {
        coordenadas: {
            handler(newCoords) {
                if (!this.map) return; // Asegúrate de que el mapa esté inicializado

                // Actualiza los marcadores en el mapa
                if (newCoords) {
                    this.updateMarkers(newCoords);
                } else {
                    // Si no hay coordenadas, limpia los marcadores
                    this.markers.forEach((marker) => this.map.removeLayer(marker));
                    this.markers = [];
                }

            },
            deep: true,
        },
        moraSeleccionada: function (newValue) {
            // Filtrar las coordenadas según la mora seleccionada
            if (newValue === "Mostrar Todos") {
                this.updateMarkers(this.coordenadas);
            } else {
                const filteredCoords = this.coordenadas.filter(coord => coord.no_mora === newValue);
                this.updateMarkers(filteredCoords);
            }
        },
    },
    methods: {
        initMap() {
            this.map = L.map("map").setView([14.5, -86.5], 7);
            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                attribution: "&copy; OpenStreetMap contributors",
            }).addTo(this.map);

            if (this.coordenadas.length) {
                this.updateMarkers(this.coordenadas);
            }
        },
        getCoordenadas() {

            // Filtros
            const filters = {
                mora: this.moraSeleccionada,
                sucursal: this.sucursal,
            };

            console.log(filters);

            fetch(process.env.VUE_APP_API_URL + "/admin/credito/coordenadas-beneficiarios", Security.requestOptions(filters))
                .then((response) => response.json())
                .then((response) => {
                    if (response.error) {
                        this.$emit('error', response.message);
                        console.error(response.message);
                    } else {
                        console.log('Coordenadas Obtenidas', response.data.dashboardData);
                        this.coordenadas = response.data.dashboardData;
                    }
                })
                .catch(error => {
                    this.$emit('error', error);
                });

            this.ready = true;
        },
        async updateMarkers(newCoords) {
            // Limpiar los marcadores anteriores
            this.markers.forEach((marker) => this.map.removeLayer(marker));
            this.markers = [];
            // Limpiar contadores
            this.contadorDepartamentos = {};
            this.contadorMunicipios = {};
            // Añadir los marcadores de las coordenadas en el mapa
            for (const coord of newCoords) {
                let marker

                if (coord.no_mora === "Al Día") {
                    this.customIcon = L.icon({
                        iconUrl: `${process.env.VUE_APP_IMAGE_URL}/AlDia.svg`,
                        iconSize: [32, 32],
                        iconAnchor: [16, 32],
                        popupAnchor: [0, -32],
                    })
                } else {
                    this.customIcon = L.icon({
                        iconUrl: `${process.env.VUE_APP_IMAGE_URL}/ahdecoLocation.svg`,
                        iconSize: [32, 32],
                        iconAnchor: [16, 32],
                        popupAnchor: [0, -32],
                    })
                }
                switch (coord.no_mora) {
                    case "Al Día":
                        this.customIcon = L.icon({
                            iconUrl: `${process.env.VUE_APP_IMAGE_URL}/AlDia.svg`,
                            iconSize: [32, 32],
                            iconAnchor: [16, 32],
                            popupAnchor: [0, -32],
                        })
                        break;
                    case "1-30":
                        this.customIcon = L.icon({
                            iconUrl: `${process.env.VUE_APP_IMAGE_URL}/30.svg`,
                            iconSize: [32, 32],
                            iconAnchor: [16, 32],
                            popupAnchor: [0, -32],
                        })
                        break;

                    case "31-60":
                        this.customIcon = L.icon({
                            iconUrl: `${process.env.VUE_APP_IMAGE_URL}/60.svg`,
                            iconSize: [32, 32],
                            iconAnchor: [16, 32],
                            popupAnchor: [0, -32],
                        })
                        break;
                    case "61-89":
                        this.customIcon = L.icon({
                            iconUrl: `${process.env.VUE_APP_IMAGE_URL}/89.svg`,
                            iconSize: [32, 32],
                            iconAnchor: [16, 32],
                            popupAnchor: [0, -32],
                        })
                        break;
                    case "90-Adelante":
                        this.customIcon = L.icon({
                            iconUrl: `${process.env.VUE_APP_IMAGE_URL}/adelante.svg`,
                            iconSize: [32, 32],
                            iconAnchor: [16, 32],
                            popupAnchor: [0, -32],
                        })
                        break;
                }

                marker = L.marker([coord.lat, coord.lng], { icon: this.customIcon }).addTo(this.map);
                marker.on("click", () => this.onMarkerClick(coord));
                this.markers.push(marker);
            }

            // Ahora procesamos y contamos después de que todos los marcadores se agreguen

        },
        async contarDepartamentosYMuncipios(newCoords) {
            for (const coord of newCoords) {
                // Si no tenemos departamento ni municipio, los obtenemos usando la API
                if (!coord.departamento || !coord.municipio) {
                    const { departamento, municipio } = await this.getUbicacion(coord.lat, coord.lng);
                    coord.departamento = departamento;
                    coord.municipio = municipio;
                }

                // Aquí, actualizamos los contadores de departamentos y municipios
                this.contarPunteros(coord.departamento, coord.municipio);
            }

        },

        contarPunteros(departamento, municipio) {
            // Asignación directa de los valores a los objetos
            if (departamento) {
                this.contadorDepartamentos[departamento] = (this.contadorDepartamentos[departamento] || 0) + 1;
            }
            if (municipio) {
                this.contadorMunicipios[municipio] = (this.contadorMunicipios[municipio] || 0) + 1;
            }

            // Forzar una actualización del DOM si es necesario
            this.$nextTick(() => {
                console.log("DOM actualizado", this.contadorDepartamentos, this.contadorMunicipios);
            });
        },

        async getUbicacion(lat, lng) {
            try {
                // Realizamos la solicitud de reverse geocoding a OpenStreetMap
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
                );
                const data = await response.json();
                return {
                    departamento: data.address.state || "Desconocido", // Si no tenemos estado, asignamos "Desconocido"
                    municipio: data.address.town || "Desconocido",    // Lo mismo para el municipio
                };
            } catch (error) {
                console.error("Error obteniendo ubicación:", error);
                return { departamento: "Desconocido", municipio: "Desconocido" }; // Valores por defecto en caso de error
            }
        },

        async onMarkerClick(coord) {
            this.beneficiario = coord;
            this.modalVisible = true;

            console.log("Beneficiario seleccionado:", coord);

        },
        closeModal() {
            this.modalVisible = false;
        },
        irCredito(dni) {
            this.guardarEnLocalStorage('busqueda', dni);
            window.open('/cartera', '_blank');
        },
        guardarEnLocalStorage(clave, arrayDeObjetos, tamañoMaximo = 5 * 1024 * 1024) { // 5MB por defecto
            // Convertimos el array de objetos a una cadena JSON
            const jsonString = JSON.stringify(arrayDeObjetos);

            // Calculamos el tamaño del JSON en bytes
            const tamañoJSON = new Blob([jsonString]).size;

            // Verificamos si el tamaño es menor al límite permitido
            if (tamañoJSON <= tamañoMaximo) {
                // Si el tamaño es adecuado, lo guardamos en el localStorage
                localStorage.setItem(clave, jsonString);
                console.log(`Guardado correctamente en localStorage. Tamaño: ${tamañoJSON} bytes`);
            } else {
                console.error(`Error: El array excede el tamaño máximo permitido de ${tamañoMaximo} bytes.`);
            }
        },
    },
};
</script>

<style scoped>
.map-container {
    width: 100%;
    height: 600px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.info-panel {
    margin-top: 10px;
    padding: 10px;
    background: #f8f9fa;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
}

.punto-referencia {
    border: 1px solid #5e5e5e;

    border-radius: 5px;
    padding: 5px;
    /* margin: 5px; */
}
</style>
